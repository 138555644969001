import React from "react"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FaRegCopy } from "react-icons/fa"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>{data.site.siteMetadata.header_menu.privacy_policy}</title>
    </Helmet>
    <div>
      <h3
        css={css`
          border-left: 4px solid #333;
          padding-left: 0.5rem;
        `}
      >
        {data.site.siteMetadata.header_menu.privacy_policy}
      </h3>
      <ul
        css={css`
          list-style-type: decimal;
          li {
            margin-top: 2rem;
          }
        `}
      >
        はちうむ合同会社（以下「当社」）は、ここに個人情報保護方針を定め、個人情報の管理を適切かつ安全に取り扱うよう努めます。
        <li>
          当社は、お客様の個人情報を以下の目的以外には利用しません。
          <p>
            1-お客様から個人情報に関する業務を委託された場合において、委託された当該業務を適切に遂行するため
          </p>
          <p>2-お客様との取引を適切かつ円滑に遂行するため</p>
          <p>3-お問い合わせへのご対応のため</p>
        </li>
        <li>当社は、お客様の個人情報を他社、第三者に提供しません。</li>
        <li>
          当社は、お客様の個人情報の取り扱いに関係する日本の法令、その他の規範を遵守します。
        </li>
        <li>
          当社は、お客様の個人情報について、適切な安全措置を講ずることにより、漏えい、改ざん、紛失などの危険防止に努めます。
        </li>
        <li>
          当社は、個人情報の取り扱いに関して、定期的に見直しを行い、常に継続的改善に努めます。
        </li>
        <li>
          <p>個人情報の取り扱いに関する問い合わせ先(E-mail)</p>
          {data.site.siteMetadata.email}
          <CopyToClipboard text={data.site.siteMetadata.email}>
            <button
              css={css`
                padding: 0;
                margin: 0;
                margin-left: 0.5rem;
                background: none;
                border: 0;
                line-height: 0;
                overflow: visible;
                cursor: pointer;
              `}
            >
              <FaRegCopy />
            </button>
          </CopyToClipboard>
        </li>
      </ul>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        header_menu {
          privacy_policy
        }
        email
      }
    }
  }
`
